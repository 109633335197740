.dropdown{
    margin-right: 15px;
}
.dropdown-status{
    margin-right: 0px !important;
}
.dropdown button{
    border-radius: 4px;
    border-color: #C2C2C2;
    margin-left: 0 !important;
    min-width: 130px !important;
    padding: 7px 5px !important;
    width: 100%;
}
.dropdown span{
    margin-right: 5px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    
}
.dropdown button span{
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    width: 185px;
}

.dropdown-createdon button span{
    width: unset;
}

.dropdown button img{
    float: right;
}

.dropdown-menu {
    display: block;
    border-radius: 0 !important;
    border-color: #C2C2C2 !important;
    padding: 0 !important;
    min-width: 8.2rem !important;
    width: 100%;
}
.dropdown-menu{
    overflow-y: auto;
    height: 130px;
    overflow-x: hidden;
}

/*.dropdown-status button span{
    height: 14.5px;
}*/

.dropdown-item {
    padding: 0.15rem 0.5rem !important;
    line-height: 1.8;
}
.dropdown-item span{
    display: block;
    width: 100%;
}

.dropdown-item.active, 
.dropdown-item:active{
    background-color: #F6F6F6 !important;
}

.dropdown-item:hover{
    background-color: #F6F6F6 !important;
}

.dropdown .display-true{
    display: block;
}
.dropdown .display-false{
    display: none;
}
.dropdown-menu.items-0{
    display: none !important;
}