@import "../../styles/font.scss";
@import "../../styles/colors.scss";

.site-header {
    width: 100%;
    padding: 5px 20px;
    border-bottom: 1px solid #F0F0F0;
    position: fixed;
    background: $white;
    z-index: 1;
}
.site-header .row {
    justify-content: space-between;
}
.site-header .row>* {
    width: unset;
}
.site-header ul li {
    display: inline-block;
    cursor: pointer;
} 

.site-header p ,.right-user-ul h1{
    margin: 0;
    padding: 0;
    line-height: 2.4;
}

.right-user-ul .icons{
    height: 12px;
}

.site-header .logo-wrapper {
    position: relative;
}
.site-header .logo-wrapper img{
    padding: 5px 0px;
}

/*header profile section*/

.right-user-ul .media {
    position: relative;
    color: $text-black;
    cursor: pointer;
}

.image-upload {
    background-size: contain;
    background-repeat: no-repeat;

    position: relative;
    border-radius: 50%;
    margin-right: 5px;
}
.user-dash-pros, .user-dash-pros img {
    height: 35px;
    width: 35px;
}

.right-user-ul .media-body h4, .right-user-ul .media-body p {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.media-heading {
    margin-top: 0;
    margin-bottom: 5px;
}

.pro-drop-downhead {
    position: absolute;
    width: 150px;
    background: $white;
    border: 1px solid #ede9ef;
    transition: all .3s linear 0s;
    -o-transition: all .3s linear 0s;
    -moz-transition: all .3s linear 0s;
    -webkit-transition: all .3s linear 0s;
    z-index: 1;
    right: 15px;
    top:47px;
}

.sub-profile .right-user-ul .pro-drop-downhead {
    top: 100%;
    visibility: visible;
    opacity: 1;
}
.pro-drop-downhead ul{
    padding: 0;
    margin: 0;
}
.pro-drop-downhead > ul > li {
    list-style-type: none;
    display: block;
}
.pro-drop-downhead li a {
    display: block;
    padding: 5px 10px;
}

/*end profile*/

.separator {
    border: 0.5px solid #707070;
    background-color: #707070;
    position: absolute;
    top: 10%;
    height: 90%;
    display: inline;
    margin-left: 9%;
    opacity: 0.5;
}

.header-text {
    position: absolute;
    margin-left: 18%;
    top: 25%;
    letter-spacing: 0;
    font-size: $content;
    color: $text-black;
    text-align: left;
    line-height: 22px;
    @extend .Graphik_Bold;
}