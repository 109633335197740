.storybook-button-loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  width: 14px;
  height: 14px;
  display: inline-block;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.storybook-button-loader.dropdown-loader,.storybook-button-loader.table-loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  width: 25px;
  height: 25px;
  
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  z-index: 99;
  position: absolute;
}
.storybook-button-loader.dropdown-loader{
  top:30%;
  display: inline-block;
}
.storybook-button-loader.table-loader{
  display: block;
  margin-top: 7%;
}


@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}