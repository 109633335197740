@import "../../styles/colors.scss";
@import "../../styles/font.scss";

.customTable {
  border: .1mm solid $table-border !important;
  border-left-color: $table-border2 !important;
  border-right-color: $table-border2 !important;

  thead tr,
  tbody tr {
    border-bottom: .1mm solid $table-border !important;
  }

  .msgContainer {
    display: flex;
    padding: 0;
  }

  .message {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
    min-width: 80%;
  }

  .noBodyData {
    max-height: 1%;
    min-height: 1%;
    border-bottom: 0 solid white !important;
  }

  tbody {
    display: block;
    table-layout: fixed;
    max-height: 70vh;
    min-height: 70vh;

    @media only screen and (min-width : 1824px) {
      max-height: 53vh;
      min-height: 53vh;
    }
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  th {
    background-color: $heading-background !important;
    font-weight: normal;
    text-align: left;
  }

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  tbody:not(.noBodyData) tr:hover {
    background-color: $hoverColor !important;
  }

  tbody.noBodyData td {
    padding: 1%;
  }

  tbody.noBodyData tr {
    border-bottom: 0 solid white !important;
  }

  .action {
    color: $majesco-blue;
    font-weight: 600;
    cursor: pointer;
  }

  i {
    cursor: pointer;
  }

  .searchIcon {
    height: 16px;
    width: 16px;
    margin-left: 4px;
    margin-top: 3px
  }

  .arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 10px;
    margin-bottom: 3px !important;
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-bottom: 0px !important;
  }

  .arrow-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid black;
  }


}

.sweetAlert {
  .swal-text {
    @extend .Inter;
    font-size: 16px;
    line-height: 1.5;
  }

  .swal-title {
    margin: 0px;
    font-size: 16px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
    margin-bottom: 28px;
  }
}