
.login-reg-page .left,.login-reg-page .right{
    vertical-align: middle;
    display: table-cell;
}

.login-reg-page .right{
      background-color: #F9F9F9;
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
}
.login-reg-page .right p,.login-reg-page .left p{
    margin: 0;
    padding: 0;
}
.login-reg-page .right p,.login-reg-page .right .storybook-heading{
  color:white;
}
.login-reg-page .right .login-content{
    width: 65%;
}
.login-reg-page .left p{
 text-align: initial;
}

.login-reg-page > .row {
    height: 100%;
    vertical-align: middle;
    display: table;
    width: 100%;
    position: absolute;
}

.login-reg-page form .storybook-heading{
    text-align: justify;
    margin-bottom: 10px;
}
.logo-wrapper-outside{
    position:absolute;
    padding:25px;
}
.logo-wrapper-outside .separator{
    top:8px;
    position:relative;
    margin: 5px;
    font-size: 25px;
}
.logo-wrapper-outside .header-text{
    margin-left:0;
    top:4px;
    position: relative;
}

.login-reg-page .footer{
    width: auto;
    border: none;
    background-color: unset;
    bottom: 0;
    margin-left: 5%;
}

.login-reg-page .col {
    padding-right: calc(var(--bs-gutter-x) * .5) !important;
    padding-left: calc(var(--bs-gutter-x) * .5) !important;
    margin-top: var(--bs-gutter-y) !important;
}


.login-reg-page .right .storybook-heading{
    width: 70%;
}
.login-reg-page .right p{
    width: 60%;
}

.help-link {
    color: #0574ac;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: .5px;
    text-align: center;
}
button span img {
    height: 14px;
    width: 14px;
    margin-left: 5px;
    margin-bottom: 5px;
}