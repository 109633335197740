/*
 common and global classes/elements CSS are added here
*/
@import "./styles/font.scss";
@import "./styles/colors.scss";
@import "./styles/pixelConstants";
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:$text-black;
  background-color: $white;
  font-size: $content-small;
  @extend .Inter;
}
p, span {
  color:$text-black;
  font-size: $content-small;
  @extend .Inter;
}

.row>* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.icons {
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.disable,.inprogress-true{
  cursor: not-allowed !important;
  pointer-events: none;
  background: #E6E6E6 0% 0% no-repeat padding-box;
  background-color: #E6E6E6 !important;
  color:#000000;
  border: 1px solid #E6E6E6 !important;
}
.MuiLinearProgress-root{
  z-index: 999 !important;
  top:47px;
  height: 2px !important;
}
.pageloader-true{
  opacity: 0.1;
}