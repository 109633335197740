@import "./colors.scss";
@import "./pixelConstants.scss";

/********** Common for All Fonts **********/

.Bold {
    font-weight: 600;
}

/*********** Inter fonts **************/

.Inter {
    font-family: "Source-sans-pro",Serif,Sans-serif;//"Inter", "Inter", sans-serif;
}


.InterSemiBold {
    font-family: "Source-sans-pro",Serif,Sans-serif,cursive,fantasy;//"Inter Semibold", "Inter", sans-serif;
}

.Inter_Bold {
    @extend .Inter;
    @extend .Bold;
}

.Inter_Black {
    @extend .Inter;
    color:$text-black;
}

.Inter_Blue {
    @extend .Inter;
    color: $blue;
}

/*********** Inter fonts End **********/



/*********** Graphik fonts **************/

.Graphik {
    font-family:  "Graphik Light", "Graphik", "Graphik", sans-serif;
}

.Graphik_Bold {
    @extend .Graphik;
    font-weight: 300;
}

/*********** Graphik fonts End **********/