@import "../styles/font.scss";
@import "../styles/pixelConstants";

.storybook-heading,.swal-text {
    font-display: swap;
    display: inline-block;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    letter-spacing:0;
    width: 100%;
    @extend .Inter;
    @extend .Bold;
}
  
.storybook-heading--extra-small {
 font-size: $heading-extra-small;
}
.storybook-heading--small {
 font-size: $heading-small;
}
.storybook-heading--mid-medium {
    font-size: 2em;
    margin-top: 0.45em;
    margin-bottom: 0.45em;
}
.storybook-heading--medium {
 font-size: $heading-medium;;
}
.storybook-heading--large {
 font-size: $heading-large;;
}