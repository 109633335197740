
@import "../styles/font.scss";
@import "../styles/colors.scss";
@import "../styles/pixelConstants";

.storybook-button {
  font-display: swap;
  font-weight: normal;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  line-height: 1.2;
  @extend .Inter;
}
.storybook-button--primary {
  color: $white;
  background-color: $majesco-blue;
  border: 1px solid $majesco-blue;
}

.storybook-button--secondary {
  color:  $majesco-blue;
  background-color: $white;
}
.storybook-button.disable {
  color:$white;
}

.storybook-button--border{
  border: 1px solid $majesco-blue;
}

.storybook-button--small {
  font-size: 14px;
  padding: 10px 16px;
}
.storybook-button--medium {
  font-size: 15px;
  padding: 11px 20px;
}
.storybook-button--large{
  font-size: 16px;
  padding: 10px 20px;
}

.storybook-button--extra-large {
  font-size: 18px;
  padding: 12px 24px;
  width: 100%;
}