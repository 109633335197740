footer {
    border: 1px solid #F6F6F6;
    text-align: center;
    width: 100%;
    bottom: 0;
    position: fixed;
    background-color: white;
}

footer .info-wrapper{
    padding: 10px;
}
