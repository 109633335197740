/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .dashboard-container{
    padding: 70px 30px !important;
  }  

  .login-reg-page .footer {
      width: 100%;
      margin-left: 0;
  }
    
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  
}

/* Large modal size custom */
@media (min-width: 992px) {
  
}