.storybook-input {
  margin: 0px 0px 10px 0;
  box-sizing: border-box;
  outline: none;
  border: 0.5px solid #C2C2C2;
  border-radius: 4px;
}
.add-product .storybook-input {
  margin: 0px 0px 0px 0;
}

.storybook-input--large {
  width: 100%;
  padding: 9px 10px;
}

.storybook-input--medium {
  width: 70%;
  padding: 7px 10px;
}

.storybook-input--mediumlarge {
  width: 95% !important;
  padding: 10px !important;
}

.storybook-input--small {
  width: 50%;
  padding: 7px 10px;
}

input.error {
  border-color: red;
  border: 1px solid red;
}

.input-feedback {
  color: rgb(235, 54, 54);
  margin-bottom: 10px;
  text-align: justify;
  font-size: 13px;
}