@import "../../styles/colors.scss";
@import "../../styles/pixelConstants.scss";

.not-found {
    font-size:$content-large;
    color: $white;
    width: 100%;
    background-color: $majesco-dark-blue;
    padding: 60px 30px;
}
.not-found .not-found__heading {
    margin-bottom: 30px;
}

.not-found__content{
    color:$white;
    font-size:$content-medium;
}
.not-found .inner{
    margin: 20px
}
.not-found a{
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid;
    padding: 10px;
    font-size:$content-medium;
}

.not-found a:hover{
    color: $majesco-dark-blue;
    background-color: $white;
}
