
$content:16px;
$content-small:13px;
$content-medium:20px;
$content-large:30px;

$heading-large:40px;
$heading-medium:32px;
$heading-mid-medium:28px;
$heading-small:15px;
$heading-extra-small:13px;