@import "../../styles/colors.scss";

%statusColorCode {
    .total {
        background-color: #227cb1;
    }

    .fail {
        background-color: #f92b2b;
    }

    .inprogress {
        background-color: #e68f44;
    }

    .fault {
        background-color: #d345af;
    }

    .queue {
        background-color: #2222b1;
    }

    .complete {
        background-color: #34d188;
    }

    .skipped {
        background-color: #808080;
    }
}

.statusRow {
    @extend %statusColorCode;
}

.statusText {
    text-align: left;
    color: white;
    padding-left: 2px;
}

.modalComponent .btn-close {
    margin-right: 4px !important;
}

.statusTable {
    border: .1mm solid $table-border !important;
    border-left-color: $table-border2 !important;
    border-right-color: $table-border2 !important;
    height: 100%;

    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    th {
        text-align: center;
    }

    tbody {
        display: block;
        table-layout: fixed;
        max-height: 100%;
        min-height: 100%;

        @media only screen and (min-width : 1824px) {
            max-height: 53vh;
            min-height: 53vh;
        }
    }
}

.dashboard-container {
    padding: 70px 90px !important;

    .dashboard-header {

        .url-map-btn,
        .go-btn,
        .reset-sel-btn {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-end;
            align-items: center;
            justify-content: flex-end;
        }

        .go-btn button {
            margin-left: 10px;
        }

        .row:last-child {
            margin-top: 5px;
        }

        .dashboard-heading {
            margin-bottom: 30px;
        }

        .dropdown-loader-container {
            text-align: center;
        }
    }

    .statusRow {
        color: white;
        text-align: center;
        font-weight: bold;

        @extend %statusColorCode;
    }


    .table-loader-container .col {
        display: flex;
        position: relative;
        align-content: stretch;
        align-items: baseline;
        justify-content: center;
    }
}

//modal 
.Overlay {
    background-color: rgba(0, 0, 0) !important;
    opacity: 70% !important;
}

.bigModalCustom {
    // max-width: 80% !important;
    margin: auto !important;

    .custom_modal_content {
        z-index: 2000 !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
    }

    .modal-body {
        min-height: 200px !important;
        max-height: 200px !important;
    }

    .modal-header {
        padding: 7px;
        padding-left: 15px;
        padding-top: 10px;
    }

    button.close {
        background-color: $majesco-blue;
        border-color: $majesco-blue;
    }

    .descriptionStyle {
        white-space: pre !important;
        font-family: monospace !important;
    }
}

//small modal
.smallModalCustom {
    @extend .bigModalCustom;

    .modal-body {
        min-height: 80px !important;
        max-height: 80px !important;
    }
}

.whatisit:hover {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.whatisit {
    font-size: 12px;
}

.statusModalCustom {
    @extend .bigModalCustom;

    &.modal-dialog {
        max-width: 56% !important;
    }

    .modal-body {
        min-height: 200px !important;
        max-height: 250px !important;
    }

}